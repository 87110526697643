// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-boutique-js": () => import("./../../../src/pages/boutique.js" /* webpackChunkName: "component---src-pages-boutique-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallerie-js": () => import("./../../../src/pages/gallerie.js" /* webpackChunkName: "component---src-pages-gallerie-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nettoyage-js": () => import("./../../../src/pages/nettoyage.js" /* webpackChunkName: "component---src-pages-nettoyage-js" */)
}

